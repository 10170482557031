





































































































































































































































































































































import useAuthPermission from "@/use/authPermissions";
import useMisc from "@/use/misc";
import useParticipant from "@/use/participant";
import countriesList from "@/assets/data/countries.json";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MParticipantPresenceList: () =>
      import(
        "@/components/molecules/participant/m-participant-presence-list.vue"
      ),
  },
  props: {
    participant: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      participant: {} as any,
      presenceDialog: false,
      additionalServices: [],
      specialization: [],
      currency: "PLN",
    });

    const {
      getParticipantStatusName,
      getParticipantInvoiceType,
    } = useParticipant({ root });
    const { getLanguageVersion } = useMisc({ root });
    const { participantsManagement } = useAuthPermission({
      root,
    });

    const additionalServices = () => {
      if (state.participant.services && state.participant.services.length) {
        state.additionalServices = state.participant.services
          .map((el: any) => el.service)
          .reduce((acc: any, value: any) => {
            if (!acc[value.id]) {
              acc[value.id] = [];
            }
            acc[value.id].push(value);
            return acc;
          }, {});
      }
    };

    onMounted(additionalServices);

    const fetchParticipant = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`participant/${props.participant.id}`)
        .then(({ data: { participant } }) => {
          state.participant = participant;
          state.currency = participant.event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchParticipant);

    const fetchSpecialization = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("specialization")
        .then(({ data: { specializations } }) => {
          state.specialization = specializations;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.specialization = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchSpecialization);

    const sumValues = (type: string, fee: any, services: any, hotel: any) => {
      if (type === "toPay" && fee) {
        const value = (
          parseFloat(fee.toPay) +
          (services.length > 0
            ? parseFloat(
                services.reduce(
                  (acc: any, obj: any) => acc + (parseFloat(obj.toPay) || 0),
                  0
                )
              )
            : 0) +
          (hotel ? parseFloat(hotel.toPay) : 0)
        ).toFixed(2);

        return value;
      } else if (type === "paid" && fee) {
        const value = (
          parseFloat(fee.paid) +
          (services.length > 0
            ? parseFloat(
                services.reduce(
                  (acc: any, obj: any) => acc + (parseFloat(obj.paid) || 0),
                  0
                )
              )
            : 0) +
          (hotel ? parseFloat(hotel.paid) : 0)
        ).toFixed(2);

        return value;
      }
    };

    return {
      state,
      countriesList,
      getParticipantStatusName,
      getParticipantInvoiceType,
      getLanguageVersion,
      participantsManagement,
      sumValues,
    };
  },
});
